<template>
	<div :style="noticeBg">
		<mt-header :title="$t('home.noticeTitle')" class="title-header">
			<router-link to="/main" slot="left" style="color: #FFFFFF;">
				<mt-button icon="back"></mt-button>
			</router-link>
		</mt-header>
		
		<mt-swipe :auto="4000" style="height: 90%; width: 100%; position: absolute; top: 64px; max-width: 600px; margin: auto;">
			<mt-swipe-item v-for="item in noticeList" :key="item.noticeId" class="row-content" style="margin: 20px 0px; justify-content: center; text-align: center; color: #888888; font-size: 18px; overflow: scroll;">
				<div style="height: auto; display: flex; flex-direction: column;">
					<div style="padding: 0px 20px; color: #333333; font-weight: bold; font-size: 20px;">
						<label v-text="item.noticeTitle"></label>
					</div>
					<div style="padding: 0px 20px; color: #888888; font-size: 14px; margin-top: 10px; margin-bottom: 10px;">
						<label v-text="item.createTime"></label>
						<label v-text="item.remark" style="margin-left: 20px;"></label>
					</div>
					<div style="padding: 0px 20px; font-weight: bold;">
						<label v-html="item.noticeContent"></label>
					</div>
				</div>
			</mt-swipe-item>
		</mt-swipe>	
	</div>
</template>

<script>
	import "@/styles/common.css"
	import { Indicator } from 'mint-ui';
	
	export default {
		name: 'Notice',
		data:() => ({
			noticeBg : {
				height: "100vh",
				backgroundImage: "url(" + require("../../assets/login/login-bg.jpeg") + ")",
				backgroundRepeat: "no-repeat",
				backgroundPosition: "fixed",
				backgroundSize: "100%",
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				overflow: "auto",
				noticeList: [],
			},
			noticeList: []
		}),
		mounted() {
			Indicator.open({
				text: '',
				spinnerType: 'fading-circle'
			});
			// 请求公告信息
			this.$request.notice({}).then((data) => {
				Indicator.close();
				this.$data.noticeList = data;
			});
		}
	}
</script>

<style>
	.title-header {
		height: 54px; 
		background-color: #009245; 
		position: absolute; top: 0px; 
		z-index: 999;
	}
</style>
